<template>
<g>
    <mask id="path-comments-inside-1" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5778 6.7544C10.6938 6.31503 10.7556 5.85364 10.7556 5.37782C10.7556 2.40773 8.3479 0 5.37782 0C2.40773 0 0 2.40773 0 5.37782C0 8.3479 2.40773 10.7556 5.37782 10.7556C6.47949 10.7556 7.5038 10.4244 8.35655 9.85601L12.2528 9.81134L10.5778 6.7544Z"/>
    </mask>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5778 6.7544C10.6938 6.31503 10.7556 5.85364 10.7556 5.37782C10.7556 2.40773 8.3479 0 5.37782 0C2.40773 0 0 2.40773 0 5.37782C0 8.3479 2.40773 10.7556 5.37782 10.7556C6.47949 10.7556 7.5038 10.4244 8.35655 9.85601L12.2528 9.81134L10.5778 6.7544Z" :fill="fill"/>
    <path d="M10.5778 6.7544L9.61096 6.49912L9.50909 6.88495L9.70084 7.23491L10.5778 6.7544ZM8.35655 9.85601L8.34509 8.85607L8.04864 8.85947L7.80195 9.02389L8.35655 9.85601ZM12.2528 9.81134L12.2642 10.8113L13.9305 10.7922L13.1298 9.33082L12.2528 9.81134ZM9.75563 5.37782C9.75563 5.76672 9.70516 6.14233 9.61096 6.49912L11.5447 7.00967C11.6825 6.48773 11.7556 5.94056 11.7556 5.37782H9.75563ZM5.37782 1C7.79562 1 9.75563 2.96001 9.75563 5.37782H11.7556C11.7556 1.85545 8.90019 -1 5.37782 -1V1ZM1 5.37782C1 2.96001 2.96001 1 5.37782 1V-1C1.85545 -1 -1 1.85545 -1 5.37782H1ZM5.37782 9.75563C2.96001 9.75563 1 7.79562 1 5.37782H-1C-1 8.90019 1.85545 11.7556 5.37782 11.7556V9.75563ZM7.80195 9.02389C7.10834 9.48618 6.27606 9.75563 5.37782 9.75563V11.7556C6.68292 11.7556 7.89925 11.3625 8.91115 10.6881L7.80195 9.02389ZM12.2413 8.81141L8.34509 8.85607L8.36802 10.8559L12.2642 10.8113L12.2413 8.81141ZM9.70084 7.23491L11.3758 10.2919L13.1298 9.33082L11.4548 6.27388L9.70084 7.23491Z" mask="url(#path-comments-inside-1)"/>
</g>

</template>

<script>
export default {
    props: {
        fill: {
            type: String,
            default: 'white'
        }
    }
}
</script>