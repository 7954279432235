<template lang="pug">
article.media-card
  .media-card__info
    span.media-card__info-time {{ card.year }}
    div.media-card__info-icons
      span
        icon-base.media-card__info-icon(:icon-name="`comment-${card.id}`" width="12" height="12")
          icon-comments
        | {{ card.comments_count+ ' ' }}
      span
        icon-base.media-card__info-icon(:icon-name="`like-${card.id}`" width="12" height="12")
          icon-like
        | {{ card.likes_count+ ' ' }}
  NuxtLink.media-card__link(:to="`/blog/${card.meta?.slug}`")
    img.lazy__image.media-card__img(:data-src="`${imgSrc}`" :alt="card.title")
  .media-card__tags
    NuxtLink(:to="selectBlogPath(tag)" v-for="tag in card.tags" :key="tag.id").media-card__tag {{ tag.name_ru }}
  NuxtLink.media-card__link(:to="`/blog/${card.meta?.slug}`")
    h4.text-bold.media-card__title {{ card.title }}
    p.media-card__text-item {{ card.title_extra }}
</template>

<script>
import IconBase from '~/components/IconBase'
import IconLike from '~/components/icons/IconLike'
import IconComments from '~/components/icons/IconComments'

export default {
  setup() {
    const { isMobile } = useScreenSizeChecker()

    return {
      isMobile,
    }
  },
  components: {
    IconBase,
    IconLike,
    IconComments
  },
  props: {
    card: {
      type: Object,
      default: () => ({
        meta: {
          slug: 'sample-slug'
        },
        year: '27 апреля 13:30',
        likes_count: '0',
        comments_count: '0',
        title: 'Возможности интернета для продвижения себя.',
        title_extra: 'Мы вместе с вами заботимся о юридической безопасности проекта - охотно заключаем договор и NDA'
      })
    }
  },
  computed: {
    imgSrc() {
      return this.card.promo_image ? this.$getImgUrl.blog_card(this.card.promo_image.meta.download_url, this.isMobile) : ''
    },
  },
  mounted() {
    const lazyImages = document.querySelectorAll('.lazy__image')
    this.$setLazyLoading(lazyImages, '.div')
  },
  methods: {
    selectBlogPath(tag) {
      if (this.$route.path === '/blog') {
        return { query: { tag: tag.name } }
      } else {
        return { path: 'blog', query: { tag: tag.name } }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.media-card {
  width: 100%;

  @include l-desktop {
    width: 215px;
  }

  &__link {
    &:hover {
      text-decoration: none;
    }
  }

  &:hover {

    .media-card__img {
      filter: grayscale(.5);
    }

    .media-card__title,
    .media-card__text-item {
      color: $purple;
    }
  }

  &__info {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
  }

  &__info-time {
    color: $gray;
  }

  &__info-icons {
    min-width: 75px;
    display: flex;
    justify-content: space-between;
    color: $primary;
  }

  &__info-icon {
    margin-right: 5px;
  }

  &__img {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
    object-fit: cover;
    object-position: 50% 50%;

    // @include mobile {
    @media (--less-small-viewport) {
      margin-bottom: 20px;
    }
  }

  &__tags {
    line-height: 1;
    height: 1rem;
    margin-bottom: 15px;
  }

  &__tag {
    margin-right: 10px;
    color: $primary;
    font-size: 12px;
    font-weight: bold;
  }

  &__title {
    margin-bottom: 8px;
    color: $dark;
    white-space: break-spaces;
    line-height: 1.2;

    // @include mobile {
    @media (--less-small-viewport) {
      margin-bottom: 10px;
    }
  }

  &__text-item {
    font-size: 14px;
    max-height: 66px;
    overflow: hidden;
  }
}
</style>
