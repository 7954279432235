<template lang="pug">
div(
  :style="containerStyles"
  :class="right ? 'dialog-card dialog-card_right' : 'dialog-card dialog-card_left'"
  )
  slot
</template>

<script>
export default {
  setup() {
    const { isMobile } = useScreenSizeChecker()

    return {
      isMobile
    }
  },
  props: {
    css: {
      type: Object,
      default: () => ({})
    },
    right: {
      type: Boolean,
      default: false
    },
    sm: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    containerStyles() {
      return {
        '--bg-color': this.css.backgroundColor ? this.css.backgroundColor : '#fff',
        '--br-color': this.css.borderColor ? this.css.borderColor : '#00A4FF',
        '--br-size': this.sm ? '12px' : '22px',
        '--br-bottom': this.sm ? '-24px' : '-44px',
        '--sp-size': this.sm ? '10px' : '20px',
        '--sp-bottom': this.sm ? '-19px' : '-39px',
        '--card-width': !this.isMobile ? (this.css.width ? this.css.width : '350px') : '100%',
        '--card-padding': this.css.padding ? this.css.padding : '30px',
        '--font-size': this.css.fontSize ? this.css.fontSize : '18px',
        ...this.css
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-card {
  width: var(--card-width);
  padding: var(--card-padding);
  background-color: var(--bg-color);
  border: 2px solid var(--br-color);
  color: var(--br-color);
  font-size: var(--font-size);
  position: relative;

  &_left,
  &_right {

    &:before {
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      bottom: var(--br-bottom);
    }

    &:after {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      bottom: var(--sp-bottom);
    }
  }

  &_left {

    &:before {
      content: "";
      border-left: var(--br-size) solid var(--br-color);
      border-right: var(--br-size) solid transparent;
      border-top: var(--br-size) solid var(--br-color);
      border-bottom: var(--br-size) solid transparent;
      left: -2px;
    }

    &:after {
      content: "";
      border-left: var(--sp-size) solid #fff;
      border-right: var(--sp-size) solid transparent;
      border-top: var(--sp-size) solid #fff;
      border-bottom: var(--sp-size) solid transparent;
      left: 0px;
    }
  }

  &_right {
    &:before {
      content: "";
      border-left: var(--br-size) solid transparent;
      border-right: var(--br-size) solid var(--br-color);
      border-top: var(--br-size) solid var(--br-color);
      border-bottom: var(--br-size) solid transparent;
      right: -2px;
    }

    &:after {
      content: "";
      border-left: var(--sp-size) solid transparent;
      border-right: var(--sp-size) solid #fff;
      border-top: var(--sp-size) solid #fff;
      border-bottom: var(--sp-size) solid transparent;
      right: 0px;
    }
  }
}
</style>
